import * as React from 'react';
import { useState } from 'react';
import './Achievement.css';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import DemoPopup from '../DemoPopup/DemoPopup';
import ImageModal from '../DemoPopup/ImageModal';

const cardsData = [
  {
    imgSrc: './images/1.給与、賞与管理.png',
    title: '給与・賞与管理システム',
    category: '給与管理システム、人事システム',
    businessType: '経理・財務、人材管理',
    os: 'Windows、MacOS、Linux',
    content: '社員の給与および賞与を効率的に管理するシステム\n 基本給や残業代、各種手当の計算に加え、賞与の支給額やその基準も自動で計算。\n 各社員ごとの給与・賞与の履歴も一元管理。',
    link: ''
  },
  {
    imgSrc: './images/2.販売管理(Web).png',
    title: '販売管理(Web)',
    category: '販売管理システム',
    businessType: '小売業、製造業',
    os: 'Windows、MacOS、Linux',
    content: '商品の売上データ、取引先情報を効率的に管理するシステム\n 売上データや取引先情報をWeb上で管理することで、業務全体の効率化とコスト削減を実現。',
    link: ''
  },
  {
    imgSrc: './images/3.販売管理(Client-Server).png',
    title: '販売管理(Client-Server)',
    category: '販売管理システム',
    businessType: '小売業、製造業',
    os: 'Windows7、Windows10、Windows11',
    content: '商品の売上データ、取引先情報を効率的に管理するシステム \n システム内でデータを視覚的に整理・表示し、売上データや取引先情報の「見える化」を実現。',
    link: ''
  },
  {
    imgSrc: './images/4.購買管理.png',
    title: '購買管理',
    category: '購買管理システム、ワークフロー管理システム',
    businessType: '小売業、製造業',
    os: 'Windows、MacOS、Linux',
    content: '購買申請から承認、発注、受領までの一連のプロセスを管理するシステム \n コミュニケーションツールとの連携を行い、リアルタイムでのスムーズなワークフローを実現。',
    link: ''
  },
  {
    imgSrc: './images/5.遠方監視.png',
    title: '遠方監視システム',
    category: 'IoTシステム',
    businessType: '製造業、エネルギー業',
    os: 'Windows、MacOS、Linux',
    content: '遠隔地から電気設備やインフラの状態をリアルタイムで監視できるシステム \n デバイスからデータを収集し、IoT技術でデータを蓄積・分析して予知保全と運用改善を実現。',
    link: ''
  },
  {
    imgSrc: './images/6.マシニングセンタ用HMI.png',
    title: 'マシニングセンタ用HMI',
    category: 'HMIシステム、CNC制御システム',
    businessType: '製造業、金属加工業',
    os: 'Windows7、Windows10、Windows11',
    content: 'マシニングセンタ用HMI（ヒューマンマシンインターフェース）\n FANUCライブラリを利用し、金属加工プログラムの選択やデータの確認、進捗状況の把握、エラーや異常の検知など多機能を実現。',
    link: ''
  },
  {
    imgSrc: './images/7.PLCプログラミングツール.png',
    title: 'PLCプログラミングツール',
    category: 'PLCプログラミングソフトウェア',
    businessType: '製造業',
    os: 'Windows7、Windows10、Windows11',
    content: 'PLC（プログラマブルロジックコントローラ）プログラミングツール\n 各種プログラミング言語（ラダー図、ファンクションブロック図、構造化テキストなど）に対応。\n PLCのプログラミング、設定、デバッグを効率的に行うことが可能。',
    link: ''
  },
  {
    imgSrc: './images/8.eラーニング (Cloud).png',
    title: 'eラーニング(Cloud)',
    category: 'eラーニングシステム',
    businessType: '製造業',
    os: 'Windows、MacOS、Linux',
    content: '製造業向けのeラーニングシステム \n 動画ストリーミングで、業界の専門家や講師による講義や実践的なトレーニングを、いつでもどこでも視聴することが可能。\n リアルタイムで受講者の視聴状況を確認できる機能を備えており、管理者は学習状況や理解度を正確に把握することが可能。',
    link: ''
  },
  {
    imgSrc: './images/9.eラーニング(On-Premise).png',
    title: 'eラーニング(On-Premise)',
    category: 'eラーニングシステム',
    businessType: '製造業',
    os: 'Windows7、Windows10、Windows11',
    content: '製造業向けのeラーニングシステム \n 文書形式のトレーニングを利用し、業界の専門家や講師による資料を、いつでもどこでも閲覧することが可能。\n 管理者は学習状況や理解度を把握することが可能。',
    link: ''
  }
];

const Achievement = () => {
  useDocumentTitle('案件実績');
  const [revealedCardIndex, setRevealedCardIndex] = useState<number | null>(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [currentDemoLink, setCurrentDemoLink] = useState('');
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [currentImgSrc, setCurrentImgSrc] = useState('');

  const reveal = (index: number) => {
    setRevealedCardIndex(index);
  };
  
  const collapse = () => {
    setRevealedCardIndex(null);
  };

  const openPopup = (demoLink: string) => {
    setCurrentDemoLink(demoLink);
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setCurrentDemoLink('');
  };

  const openImageModal = (imgSrc: string) => {
    setCurrentImgSrc(imgSrc);
    setIsImageModalOpen(true);
  };

  const closeImageModal = () => {
    setIsImageModalOpen(false);
    setCurrentImgSrc('');
  };

  return (
    <div className="control-pane">
      <div className='achievement-header'>
        <h2>案件実績</h2>
      </div>
      <div className="control-section">
        {cardsData.map((card, index) => (
          <div key={index} className="achievement-col-xs-6 col-sm-6 col-lg-6 col-md-6">
            <div className={`achievement-e-card-resize-container ${revealedCardIndex === index ? 'show-card' : ''}`}>
              <div className="e-card">
              <img className="img-responsive" src={card.imgSrc} alt="Card Image" onClick={() => openImageModal(card.imgSrc)} />
                
                {/* 表*/}
                <div id="card_revealed">
                  <div className="e-card-content">
                    <table>
                      <tbody>
                        <tr>
                          <td><div style={{ textAlign: 'left', fontWeight: 500, fontSize: '10px'}}>案件名</div></td>
                          <td><div style={{ textAlign: 'left' }}>{card.title}</div></td>
                        </tr>
                        <tr>
                          <td><div style={{ textAlign: 'left', fontWeight: 500, fontSize: '10px'}}>カテゴリ</div></td>
                          <td><div style={{ textAlign: 'left' }}>{card.category}</div></td>
                        </tr>
                        <tr>
                          <td><div style={{ textAlign: 'left', fontWeight: 500, fontSize: '10px'}}>業種・分野</div></td>
                          <td><div style={{ textAlign: 'left' }}>{card.businessType}</div></td>
                        </tr>
                        <tr>
                          <td><div style={{ textAlign: 'left', fontWeight: 500, fontSize: '10px'}}>対応OS</div></td>
                          <td><div style={{ textAlign: 'left' }}>{card.os}</div></td>
                        </tr>
                        <tr>
                          <td colSpan={2}>
                            <button id="showcarddata" onClick={() => reveal(index)} className="e-btn e-outline e-primary">
                              Know More
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                {/* 裏 */}
                <div id="card_reveal">
                  <div className="e-card-header">
                    <div className="e-card-header-caption">
                      <div className="e-card-header-title">{card.title}</div>
                    </div>
                    <button className="close-btn" onClick={collapse}>×</button>
                    <div id="card-reveal_collapse" onClick={collapse}>
                      <span className="e-icons e-collapse"></span>
                    </div>
                  </div>
                  <div className="e-card-content">
                  {card.content.split('\n').map((line, lineIndex) => (
                      <div key={lineIndex}>{line}</div>
                    ))}
                  </div>
                  <div className="e-card-actions">
                  <button onClick={() => openPopup(card.link)} className="e-btn e-outline e-primary">
                      Play Demo
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* デモ画面 */}
      <DemoPopup isOpen={isPopupOpen} onClose={closePopup} videoLink={currentDemoLink} />

      {/* 画像拡大モーダル */}
      <ImageModal isOpen={isImageModalOpen} imgSrc={currentImgSrc} onClose={closeImageModal} />

    </div>
  );
};

export default Achievement;