import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Home from './components/Home/Home.tsx';
import Business from './components/Business/Business';
import Company from './components/Company/Company';
import Achievement from './components/Achievement/Achievement';
import InHouseService from './components/InHouseService/InHouseService';
import Recruitment from './components/Recruitment/Recruitment';
import ProcessInfo from './components/Recruitment/ProcessInfo';
import JobInfo from './components/Recruitment/JobInfo';
import Contact from './components/Contact/Contact';
import CeoGreeting from './components/CeoGreeting/CeoGreeting';
import AwsPartnerAnnouncement from './components/Home/Announcements/AwsPartnerAnnouncement'; 
import HomepageRenewalAnnouncement from './components/Home/Announcements/HomepageRenewalAnnouncement'; 
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import StarrySkyWrapper from './components/StarrySky/StarrySkyWrapper';
import { Helmet } from 'react-helmet';

import '@fortawesome/fontawesome-free/css/all.min.css';

function App() {
  return (
    <Router>
      <Helmet>
        <title>【株式会社IT plus】ホームページ</title>
        <meta
          name="description"
          content="IT plusホームページ。最新の技術情報、会社案内、 案件実績、サービス概要、採用情報を提供します。"
        />
        <meta
          name="keywords"
          content="IT plus, 技術情報, サービス, 会社案内, 案件実績, 自社サービス, 採用情報"
        />
        
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebSite",
              "url": "https://www.i-t-plus.co.jp/",
              "name": "株式会社IT plus",
              "description": "IT plusホームページ。最新の技術情報、会社案内、 案件実績、サービス概要、採用情報を提供します。",
              "potentialAction": {
                "@type": "SearchAction",
                "target": "https://www.i-t-plus.co.jp/search?q={search_term}",
                "query-input": "required name=search_term"
              },
              "mainEntityOfPage": "https://www.i-t-plus.co.jp",
              "sitelink": [
                {
                  "@type": "WebPage",
                  "url": "https://www.i-t-plus.co.jp/greeting",
                  "name": "代表挨拶",
                  "description": "IT plusのCEOからの代表挨拶"
                },
                {
                  "@type": "WebPage",
                  "url": "https://www.i-t-plus.co.jp/company",
                  "name": "会社案内",
                  "description": "会社の概要、歴史、アクセスについて詳しく知ることができます。"
                },
                {
                  "@type": "WebPage",
                  "url": "https://www.i-t-plus.co.jp/company",
                  "name": "案件実績",
                  "description": "IT plusのプロジェクトの実績を紹介しています。"
                },
                {
                  "@type": "WebPage",
                  "url": "https://www.i-t-plus.co.jp/achievement",
                  "name": "自社サービス",
                  "description": "IT plusが提供する自社開発のサービスやソリューションに関する情報を提供します。"
                },
                {
                  "@type": "WebPage",
                  "url": "https://www.i-t-plus.co.jp/recruitment",
                  "name": "採用案内",
                  "description": "新卒・中途採用情報や採用までの流れついて詳しく説明しています。"
                }
              ]
            }
          `}
        </script>
      </Helmet>

      <StarrySkyWrapper />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/greeting" element={<CeoGreeting />} />
        <Route path="/business" element={<Business />} />
        <Route path="/company" element={<Company />} />
        <Route path="/achievement" element={<Achievement />} />
        <Route path="/service" element={<InHouseService />} />
        <Route path="/recruitment" element={<Recruitment />} />
        <Route path="/process-info" element={<ProcessInfo />} />
        <Route path="/job-info" element={<JobInfo />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/aws-partner-announcement" element={<AwsPartnerAnnouncement />} />
        <Route path="/hp-renewal-announcement" element={<HomepageRenewalAnnouncement />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
